import { PageTemplate } from './PageTemplate'
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CustomerCandidateComponent } from '../components/Customer/CustomerCandidateComponent'

const ShowOtherCVPage = () => {
    return (
        <PageTemplate
            type="customer" headerPage="candidatepage" headerElement="viewcv"
            leftPaneComponent={null} mainComponent={CustomerCandidateComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>
    );
}
export { ShowOtherCVPage }